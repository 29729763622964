import { Row, Col, Container } from "react-bootstrap";
import { useState } from "react";
import { Collapse } from "react-bootstrap";

function DecisionMaker() {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Container fluid style={{ paddingTop: "2rem" }}>
      {" "}
      <Row>
        <Col>
        
            <div className="center" style={{ lineHeight: "24px" }}>
              <h2
                id="decisionmaker"
                style={{
                  color: "#a84158",
                }}
              >
                DECISIONMAKER
              </h2>
              <h4>#Agile</h4>
              <p>Adaptive</p>
            </div>
            <br />
            From challenging functions over the last 18 years working abroad, I
            have developed an independence in sound decision making.
            <br />
            <br />
            #Vision in Motion:
            <br />
            10 years of personal drive in constant development.
            <br />
            In December 2008 I seized a marketing opportunity and started a new
            venture in Bangkok, Thailand.
            <br />
            With own brand ’Indochina Explorers’, I developed a specialized
            niche in inbound travel services covering at first Thailand, Vietnam
            & Malaysia, for later to expand the network with Laos, Cambodia,
            Japan and China.
            <br />
            Own product development, contracting and marketing, with several
            local country operational partners.
            <br />
            <br />
         
              <div id="test" style={{ paddingLeft: "0" }}
                onClick={() => setOpen(!open)}
                aria-controls=""
                aria-expanded={open}
              >
                <span style={{ color: "#a84158" }}>See more ..</span>
              </div>
              <Collapse in={open}>
                <div id="text1" style={{ paddingLeft: "0" }}>
                  <p style={{ marginTop: "1rem"}}>
                    Results:
                    <br />
                    I achieved great success with professional clients globally
                    in niche business areas as: Educational tours for college
                    students, custom made itineraries and special experience
                    programs, cycling trips, group travel, multi country
                    combinations, MICE ( Meetings, Incentives, Conference,
                    Events ) and luxury travel.
                    <br />
                    <br />
                    A short selection: <br />
                    2009-2012 sole supplier for SAS (Scandinavian
                    Airlines)-Leisure in Thailand, Malaysia, Vietnam, China og
                    Japan. <br />
                    // SAS Leisure closed down in 2012 //
                    <br />
                    Supplier of educational tours in Vietnam for China
                    Experience (DK), Sports / Educational for Bundabashers (SA)
                    in Thailand and Japan, Custom made programs for Voya
                    Travel’s (DK) connoisseur travelers.
                    <br />
                    Product segments ranged from: High-end customisation,
                    Educational/Edu-fun, College sports groups, extensive
                    multi-country leisure packages <br />
                  </p>
                </div>
              </Collapse>
          
      
        </Col>
        </Row>

      </Container>
      <hr />
    </>
  );
}

export { DecisionMaker };
