import { Container, Card } from "react-bootstrap";
import DJST from "../images/dontJustStandThere.jpg"

export default function Header() {
  return (
    <>
      <Container fluid style={{paddingLeft:'0', paddingRight:'0'}}>
      <>
            <Card
              style={{
                border: "0",
              }}
            >
              <Card.Img
                variant="top"
                src={DJST}
                alt="Don't just stand there"
                
              />
            </Card>
      
          </>
      
      </Container>
      <hr/>
    </>
  );
}